import './App.css';
import {GoogleLogin} from '@react-oauth/google';
import React from "react";
import axios from 'axios';
import globals from './Globals'

const LoginPhase = {
    Prompt: 0,
    Checking: 1,
    Done: 2,
    Error: 3
};

export default class LoginPage extends React.Component {
    // Var
    state = {
        phase: LoginPhase.Prompt
    }

    // Callbacks
    onLoginSuccess = (response) => {
        console.log(response);

        this.setState({phase: LoginPhase.Checking});

        // Validate with server
        axios.post(globals.serverUrl + globals.apiAuthenticateUser, {
            credential: response.credential
        }).then((response) => {
            console.log(response);

            if (response.data.ok) {
                // Save login data
                globals.loginData = {
                    name: response.data.name,
                    email: response.data.email,
                    accessToken: response.data.accessToken,
                    tokenExpireDate: response.data.tokenExpireDate
                };
                localStorage.setItem("login", JSON.stringify(globals.loginData));

                // Go to next page
                this.setState({phase: LoginPhase.Done});
                this.props.loginCallback();
            } else {
                console.log(response.data.error);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    onLoginFail = (error) => {
        console.log(error);

        this.setState({phase: LoginPhase.Error});
    };

    retryLogin = () => {
        this.setState({phase: LoginPhase.Prompt});
    }

    // Methods
    createPage(inner) {
        return (
            <div align="center">
                <br/>
                <h2>Aurecas Games</h2>
                <br/>
                <h3>Official Website:</h3>
                <a className="aurecas-link" href="https://aurecas.com">aurecas.com</a>
                <br/>
                <br/>
                <br/>
                <h3>Restricted Access for Aurecas Team:</h3>
                {inner}
            </div>
        );
    }

    render() {
        let inner;

        switch (this.state.phase) {
            case LoginPhase.Prompt:
                inner = (
                    <>
                        <GoogleLogin onSuccess={this.onLoginSuccess} onError={this.onLoginFail}/>
                    </>
                );
                break;
            case LoginPhase.Checking:
                inner = (
                    <>
                        <h3>Login: OK</h3>
                        <h4>Connecting to Aurecas Servers...</h4>
                    </>
                );
                break;
            case LoginPhase.Done:
                inner = (
                    <>
                        <h3>Login Successful</h3>
                    </>
                );
                break;
            case LoginPhase.Error:
                inner = (
                    <>
                        <h3>Login Failed</h3>
                        <button onClick={this.retryLogin}>Retry</button>
                    </>
                );
                break;
            default:
                inner = (
                    <>
                        <h1>LoginPage Unknown state: {this.state.phase}</h1>
                    </>
                );
                break;
        }

        return this.createPage(inner);
    }

    constructor(props) {
        super(props);

        // Check if there's a valid login stored
        let loginStr = localStorage.getItem("login");
        if (loginStr == null || loginStr === "") return;

        let login = JSON.parse(loginStr);
        if (new Date(login.tokenExpireDate).getTime() < Date.now()) return;

        globals.loginData = login;

        // Go to next page
        this.state.phase = LoginPhase.Done;
        this.props.loginCallback();
    }
}
