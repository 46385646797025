import React from "react";
import './App.css';
import LoginPage from './LoginPage';
import PageLayout from "./PageLayout";
import globals from "./Globals";

export default class App extends React.Component {
    state = {
        loggedIn: false
    }

    loggedIn = () => {
        this.setState({ loggedIn: true });
    }

    loggedOut = () => {
        localStorage.removeItem("login");
        globals.loginData = null;

        this.setState({ loggedIn: false });
    }

    render() {
        if (!this.state.loggedIn) {
            return <LoginPage loginCallback={this.loggedIn} />
        }

        return (
            <PageLayout logoutCallback={this.loggedOut} />
        );
    }
}