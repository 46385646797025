import React, { useRef, useEffect } from "react";
import './Feedbacks.css';
import axios from "axios";
import globals from "./Globals";
import FeedbackItem from "./FeedbackItem";

export default class FeedbacksPage extends React.Component {
    state = {
        feedbackList: [],
        loadedAll: false
    }

    isFetching= false

    // Methods
    fetchFeedbacks = () => {
        if (this.isFetching) return;
        this.isFetching = true;
        const count = 10;

        axios.post(globals.serverUrl + globals.apiGetFeedbacks, {
            accessToken: globals.loginData.accessToken,
            start: this.state.feedbackList.length,
            count: count
        }).then((response) => {
            if (response.data.ok) {
                const list = this.state.feedbackList.concat(response.data.list);

                this.setState({ feedbackList: list, loadedAll: response.data.list.length < count });
            } else {
                if (response.data.refreshLogin) {
                    this.props.logoutCallback();
                }
                console.log(response.data.error);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.isFetching = false;
        });
    }

    waitTranslation = (feedbackId, attempts) => {
        // Find feedback
        if (this.state == null || this.state.feedbackList == null) return;
        const feedback = this.state.feedbackList.find(item => item.id === feedbackId);
        if (feedback == null) return;

        // Check if translation was successful
        if (feedback.messages.slice(-1)[0].translation != null) {
            return;
        }

        // Pool server
        axios.post(globals.serverUrl + globals.apiGetFeedback, {
            accessToken: globals.loginData.accessToken,
            id: feedbackId
        }).then((response) => {
            if (response.data.ok) {
                const message = response.data.data.messages.slice(-1)[0];

                if (message.translation != null) {
                    // Refresh with translation
                    const list = this.state.feedbackList.slice();
                    const index = list.findIndex(item => item.id === feedbackId);
                    if (index < 0) return;

                    list[index] = response.data.data;

                    this.setState({feedbackList: list});
                } else {
                    // Try again after 1 second
                    if (attempts > 1) {
                        setTimeout(() => {
                            this.waitTranslation(feedbackId, attempts - 1)
                        }, 1000);
                    }
                }
            } else {
                if (response.data.refreshLogin) {
                    this.props.logoutCallback();
                }
                console.log(response.data.error);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    updateMessages = (data) => {
        for (let i = 0; i < this.state.feedbackList.length; i++) {
            if (this.state.feedbackList[i].id === data.id) {
                let temp = this.state.feedbackList.slice();
                temp[i] = data;
                this.setState({ feedbackList: temp });
                break;
            }
        }
    }

    onScroll = () => {
        const body = document.body;
        const distance = body.offsetHeight - window.innerHeight - window.scrollY;

        if (!this.state.loadedAll && distance < 200) {
            this.fetchFeedbacks();
        }
    };

    render() {
        return (
            <>
                <h2>Feedbacks</h2>
                {this.state.feedbackList.map(f =>
                    <FeedbackItem key={f.id} data={f} updateCallback={this.updateMessages} logoutCallback={this.props.logoutCallback}
                                  waitCallback={this.waitTranslation} />
                )}
            </>
        );
    }

    componentDidMount() {
        this.fetchFeedbacks();

        window.addEventListener("scroll", this.onScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll, false);
    }
}