const globals = {
    // Constants
    //serverUrl: 'http://localhost:7051/api/',
    serverUrl: 'https://stick-zombies.azurewebsites.net/api/',
    apiAuthenticateUser: 'AuthenticateAdmin?code=d0sgMN-rqOc6VRQ1R6Dgo-UFhWMDep5EZZchNtPm9qnTAzFuhAddPQ==',
    apiGetAdmins: 'GetAdmins?code=GX5hhE-qPmHwJk9pW1vb-Ii_UkMY8_eZFiPvUeRdSkKnAzFuPI5WVg==',
    apiGetFeedbacks: 'GetFeedbacks?code=l_qR2dyu7WdAt3ag_niA3DOsZT8sbY22MGA1YH_4tkErAzFuV71tYg==',
    apiSendFeedback: 'AnswerFeedback?code=XUxwNLx1ci2axe1COcdK51DcbiTO_8Vcc2JV6H-wej_UAzFuz-_GRw==',
    apiDeleteFeedback: 'DeleteFeedback?code=_MBgEx_8_4de68bv-7jfiWdZQ7inFQEWT__4Iiz9DEWHAzFuRQGHdg==',
    apiGetFeedback: 'WebGetFeedback?code=Z3oYkeQ3uY78SnPS1hGta20jluULJFGdxSBs--2WQHPYAzFu4EgFuw==',
    playfabPlayerUrl: 'https://developer.playfab.com/en-us/r/t/{0}/players/{1}/overview',
    gameIds: {
        '23B3': 'Stickman vs Zombies',
        'F0D9A': 'Evil Tower'
    },
    languageNames: {
        "EN": "English", "PT": "Portuguese", "ID": "Indonesian", "KO": "Korean", "TR": "Turkish", "AZ": "Azerbaijani", "PL": "Polish",
        "FR": "French", "FA": "Persian", "HI": "Hindi", "AR": "Arabian", "VI": "Vietnamese", "ZH": "Chinese", "IT": "Italian",
        "TH": "Thai", "DE": "German", "ES": "Spanish", "RU": "Russian", "JA": "Japanese", "AF": "Afrikaans", "SQ": "Albanian",
        "BS": "Bosnian", "CA": "Catalan", "DA": "Danish", "SK": "Slovak", "SL": "Slovenian", "ET": "Estonian", "FI": "Finnish",
        "RO": "Romanian", "SV": "Swedish", "CS": "Czech", "UZ": "Uzbek", "ZU": "Zulu", "NL": "Dutch", "HU": "Hungarian", "LT": "Lithuanian",
        "MS": "Malay", "BE": "Belarusian", "BG": "Bulgarian", "KK": "Kazakh", "MK": "Macedonian", "MN": "Mongolian", "SR": "Serbian",
        "UK": "Ukrainian", "IW": "Hebrew", "MR": "Marathi"
    },

    // Vars
    loginData: null,
    admins: null,
    requestingAdmins: false
};

export default globals;