import React from "react";
import globals from "./Globals";
import axios from "axios";


export default class FeedbackMessage extends React.Component {
    isSendingFeedback = false;

    deleteMessage = () => {
        this.props.deleteCallback(this.props.index, this.props.data.hidden);
    }

    getSupportEmail = (id) => {
        if (globals.admins == null) return 'support';

        const admin = globals.admins[id];
        if (admin == null) return 'support';

        return admin.email;
    }

    render() {
        const data = this.props.data;
        const date = new Date(data.date);
        const dateStr = date.toLocaleString('pr-BR', { timeZone: 'America/Sao_Paulo' });

        const original = data.isSupport ? data.translation : data.message;
        const translation = data.isSupport ? data.message : data.translation;

        return (
            <div className="feedback-message">
                <span className="feedback-message-owner">{data.isSupport ? this.getSupportEmail(data.supportId) : 'User'}</span>
                <span> &#x2022; </span>
                <span className="feedback-message-date">{dateStr}</span>
                { data.isSupport && (
                    <>
                        <span> &#x2022; </span>
                        <span className="feedback-message-delete" onClick={this.deleteMessage}>{data.hidden ? "Show" : "Delete"}</span>
                    </>
                )}
                <br/>
                <div className={"feedback-message-holder" +
                    (data.isSupport ? " support-message" : " user-message") +
                    (data.hidden ? " deleted-message" : "")}>
                    <div className="feedback-message-line"></div>
                    {translation != null && <span className="feedback-message-original">{original}</span>}
                    <span className="feedback-message-text">{translation ?? original}</span>
                </div>
                {this.props.read && data.isSupport && <span className="feedback-read-check">&#x2713;</span>}
            </div>
        );
    }
}

// <div align="right">
// <button type="button" style="
//     margin: 0 5px;
//     background: #1a73e8;
// ">Send Without Translation</button><button type="button">Publish Reply</button></div>