import React from "react";
import FeedbacksPage from "./FeedbacksPage";
import globals from './Globals'
import axios from "axios";

const Page = {
    Overview: { id: 0, title: "Overview"},
    Feedbacks: { id: 1, title: "Feedbacks"},
    Players: { id: 2, title: "Players"}
}

export default class PageLayout extends React.Component {
    state = {
        currentPage: Page.Overview.id
    }

    buttonClicked = (page) => {
        this.setState({currentPage: page});
    };

    generateButtons(){
        let buttons = [];

        for (const page of Object.values(Page)) {
            let classes = "side-menu-button";
            if (this.state.currentPage === page.id) classes += " side-menu-button-current";

            buttons.push(
                <div key={page.id} className={classes} onClick={() => this.buttonClicked(page.id)}>
                    <div><span>{page.title}</span></div>
                </div>
            );
        }

        return buttons;
    }

    routePage() {
        switch (this.state.currentPage) {
            default:
            case Page.Overview.id:
                return <h2>Overview</h2>;
            case Page.Feedbacks.id:
                return <FeedbacksPage logoutCallback={this.props.logoutCallback}/>;
            case Page.Players.id:
                return <h2>Players</h2>;
        }
    }

    render() {
        return (
            <>
                <div id="top-menu">
                    <span id="top-menu-title">Aurecas Games</span>
                    <span id="top-menu-logout" onClick={this.props.logoutCallback}>Logout</span>
                    <span id="top-menu-email">{globals.loginData.email}</span>
                </div>
                <div id="side-menu">
                    {this.generateButtons()}
                </div>
                <div id="page-content" align="center">
                    {this.routePage()}
                </div>
            </>
        );
    }

    componentDidMount() {
        if (globals.admins != null || globals.requestingAdmins) return;
        globals.requestingAdmins = true;

        axios.post(globals.serverUrl + globals.apiGetAdmins, {
            accessToken: globals.loginData.accessToken
        }).then((response) => {
            if (response.data.ok) {
                globals.admins = response.data.list;
            } else {
                if (response.data.refreshLogin) {
                    this.props.logoutCallback();
                }

                console.log(response.data.error);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            globals.requestingAdmins = false;
        });
    }
}