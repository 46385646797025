import React from "react";
import FeedbackMessage from "./FeedbackMessage";
import axios from "axios";
import globals from "./Globals";

export default class FeedbackItem extends React.Component {
    state = {
        replyFieldText: '',
        historyOpen: false
    }

    isBusy = false;

    // Methods
    deleteFeedback = (messageId, undo) => {
        if (this.isBusy) return;
        this.isBusy = true;

        axios.post(globals.serverUrl + globals.apiDeleteFeedback, {
            accessToken: globals.loginData.accessToken,
            id: this.props.data.id,
            messageId: messageId,
            undo: undo
        }).then((response) => {
            if (response.data.ok) {
                // Update messages
                this.props.updateCallback(response.data.data);
            } else {
                if (response.data.refreshLogin) {
                    this.props.logoutCallback();
                }

                console.log(response.data.error);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.isBusy = false;
        });
    }

    // Methods
    waitTranslation = () => {
        this.props.waitCallback(this.props.data.id, 15);
    }

    sendFeedback(translate){
        if (this.isBusy) return;
        this.isBusy = true;

        axios.post(globals.serverUrl + globals.apiSendFeedback, {
            accessToken: globals.loginData.accessToken,
            id: this.props.data.id,
            message: this.state.replyFieldText,
            translate: translate
        }).then((response) => {
            if (response.data.ok) {
                // Update messages
                this.setState({ replyFieldText: '' });
                this.props.updateCallback(response.data.data);

                // Keep pooling for translation
                if (translate && response.data.language !== 'PT') {
                    setTimeout(this.waitTranslation, 1500);
                }
            } else {
                if (response.data.refreshLogin) {
                    this.props.logoutCallback();
                }

                console.log(response.data.error);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.isBusy = false;
        });
    }

    sendFeedbackTranslate = () => {
        this.sendFeedback(true);
    }

    sendFeedbackOriginal = () => {
        this.sendFeedback(false);
    }

    replyFieldChanged = (event) => {
        this.setState({ replyFieldText: event.target.value });
    }

    historyClicked = () => {
        this.setState({ historyOpen: !this.state.historyOpen });
    }

    usernameClicked = () => {
        const data = this.props.data;

        let url = globals.playfabPlayerUrl
            .replace('{0}', data.gameId)
            .replace('{1}', data.playfabId);

        window.open(url, "_blank");
    }

    render() {
        const data = this.props.data;
        const len = data.messages.length;

        return (
            <div className="feedback-item">
                <div className="feedback-user">
                    <span className="feedback-username" onClick={this.usernameClicked}>{data.username}</span>
                    <span> &#x2022; </span>
                    <span>{globals.gameIds[data.gameId]}</span>
                    <span> &#x2022; </span>
                    <span>{data.appVersion}</span>
                    <span> &#x2022; </span>
                    <span>{globals.languageNames[data.language]}</span>
                </div>
                {len > 3 && (
                    <div className="expand-messages" onClick={this.historyClicked}>
                    <span>History ({len}) </span>
                    {this.state.historyOpen ? <span>&#x25B2;</span> : <span>&#x25BC;</span>}
                </div>
                )}
                <div className="feedback-messages">
                    {data.messages.map((m, i) =>
                        (this.state.historyOpen || i >= data.messages.length - 3) &&
                            <FeedbackMessage key={i} index={i} data={m} read={data.readUntil > i} deleteCallback={this.deleteFeedback} />
                    )}
                </div>
                <form className="feedback-answer">
                    <textarea required onChange={this.replyFieldChanged} value={this.state.replyFieldText}></textarea>
                    <br/>
                    <div align="right">
                        <button type="button" onClick={this.sendFeedbackOriginal}>Send Without Translation</button>
                        <button type="button" onClick={this.sendFeedbackTranslate}>Publish Reply</button>
                    </div>
                </form>
            </div>
        );
    }

    constructor(props) {
        super(props);

        this.state.messages = props.data.messages;

        if (this.state.replyFieldText === '') {
            this.state.replyFieldText = this.props.data.suggestedReply ?? '';
        }
    }
}